import { ChangeDetectionStrategy, Component } from '@angular/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'app-intro',
  template: `
    <div class="container intro flex flex-space-between">
      <div class="intro__lead">
        <h1 style="font-family: monospace"class="intro__title">octotech.cloud</h1>
        <p class="intro__text">Integración innovadora de bots</p>
        <div class="intro__links">
          <a routerLink="/contact-us" class="button primary button--large">
          Contáctanos
          </a>
        </div>
      </div>
      <img
        class="image"
        width="450px" 
        height="450px"
        src="./assets/chatbot.svg"  
        alt="Online illustrations by Storyset (https://storyset.caom/online)"
      />
      <p>Eleva la participación de tus clientes y optimiza la comunicación con las soluciones de integración de bots de octotech.cloud. Contáctanos hoy para conocer más sobre cómo podemos transformar tu negocio. Juntos construiremos un futuro donde la comunicación sea inteligente, fluida y centrada en el cliente.</p>

    </div>
  `,
  styles: [
    `
      .intro {
        img {
          height: auto;
        }
        &.container {
          @media screen and (max-width: 1220px) {
            text-align: center;
            justify-content: center;
          }
        }
        &__title {
          font-size: 2.5rem;
          font-weight: 600;
          @media screen and (max-width: 850px) {
            & {
              font-size: 2rem;
            }
          }
        }
        &__links {
          margin-top: 40px;
        }

        &__lead {
          margin: 20px;
          max-width: 650px;
          font-size: 1.3rem;
          @media screen and (max-width: 850px) {
            & {
              font-size: 1rem;
            }
          }
        }
        &__info {
          font-size: 0.9rem;
        }
      }
    `
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule]
})
export class IntroComponent {}
