import { CommonModule, isPlatformBrowser } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Inject,
  OnInit,
  PLATFORM_ID
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-navbar',
  template: `
    <header>
      <div class="border-top"></div>
      <div class="header-inner container flex gap-30">
        <nav class="main-nav flex gap-15 flex-fit">
          <a routerLink="/home" routerLinkActive="active--exact active">
            <span class="main-nav__label">home</span>
          </a>
          <a routerLink="/servicios" routerLinkActive="active--exact active">
            <span class="main-nav__label">servicios</span>
          </a>
          <a routerLink="/productos" routerLinkActive="active--exact active">
            <span class="main-nav__label">productos</span>
          </a>
          <a routerLink="/nosotros" routerLinkActive="active--exact active">
            <span class="main-nav__label">nosotros</span>
          </a>
          <!-- <a routerLink="/app" routerLinkActive="active--exact active" (click)="redirect()">
            <span class="main-nav__label">app →</span>
          </a> -->
        </nav>
        <form>
          <ng-template></ng-template>
        </form>
      </div>
    </header>
  `,
  styleUrls: ['./navbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [RouterModule, CommonModule]
})
export class NavbarComponent {


  constructor() { }

  redirect() {
    window.location.href = environment.appRoute;
  }

  
}
