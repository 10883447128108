import { Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { environment } from './../environments/environment';
import { AppComponent } from './app.component';
import { PortalComponent } from './_shared/components/portal/portal.component';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    pathMatch: 'full'
  },
  {
    path: 'nosotros',
    loadChildren: () => import('./contact-us/contact-us.routes').then(m => m.routes)
  },
  {
    path: 'servicios',
    loadChildren: () => import('./services/services.routes').then(m => m.routes)
  },
  {
    path: 'productos',
    loadChildren: () => import('./products/products.routes').then(m => m.routes)
  },
  {
    path: 'app',
    component: PortalComponent
  },
  {
    path: '**',
    component: HomeComponent,
    pathMatch: 'full'
  }
];
